import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert
} from "@mui/material";

import ForgotPasswordController, {
  Props,
  SnackbarProps
} from "./ForgotPasswordController.web";
import { styled } from "@mui/material/styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoginLayout from "../../../components/src/LoginLayout.web";
import { createStyles, withStyles } from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";

const styles = createStyles({
  forgotForm: {
    width: '100%'
  }
});

const ResetPasswordButton = styled(Button)(() => ({
  width: '100%',
  height: '78px',
  padding: '10px 18px',
  borderRadius: '8px',
  background: 'linear-gradient(90deg, #F0644B 0%, #FAA52E 100%)',
  color: '#FFFFFF',
  fontWeight: 700,
  fontSize: '24px',
  top: '70px',
  textTransform: 'none',
}));

const CustomForgotPasswordLabel = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 48,
  lineHeight: '40px'
}));

const CustomAdditionalInfoLabel = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 20
}));

const CustomEmailOption = styled(Typography)(() => ({
  color: '#EC441E',
  borderBottom: '2px solid #EC441E',
  width: 52,
  padding: 2,
  margin: '32px 0px',
  fontWeight: 700,
  fontSize: 20
}));

const CustomInput = styled(TextField)(() => ({
  backgroundColor: "transparent",
  width: '100%',
  height: '44px',
  marginBottom: '12px',
  '& .MuiOutlinedInput-input': {
    padding: '17px 8px',
    fontSize: '18px',
  },
}));

const CustomInputLabel = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  marginTop: '20px'
}));

const CustomErrorLabel = styled(Typography)(() => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: "red"
}));

export const CustomSnackbar = ({isOpen, autoHideDuration, anchorOrigin, onClose, message, type = "error"}: SnackbarProps) => (
  <Snackbar
    open={isOpen}
    autoHideDuration={autoHideDuration}
    anchorOrigin={anchorOrigin}
    onClose={onClose}
  >
    <Alert
      severity={type === "error" ? "error" : "success"}
      variant="filled"
      sx={{ width: '100%' }}
    >
      {message}
    </Alert>
  </Snackbar>
);

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your email")
});
// Customizable Area End

export class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props

    return (
      <LoginLayout>
        <>
          <Loader loading={this.state.loading} />
          <CustomForgotPasswordLabel data-test-id="forgot-password-label">Forgot Password?</CustomForgotPasswordLabel>
          <CustomAdditionalInfoLabel data-test-id="additional-info-label">Enter your email address to get password reset link</CustomAdditionalInfoLabel>
          <CustomEmailOption data-test-id="email-option-label">Email</CustomEmailOption>

          <Box className={classes.forgotForm}>
            <Formik
              initialValues={{
                email: ""
              }}
              validationSchema={validationSchema}
              onSubmit={this.resetPassword}
              data-test-id="reset-formik"
            >
              {(props) => {
                this.updateResetPasswordFormProps(props);

                return (
                  <Form
                    data-test-id="reset-password-form"
                    onSubmit={props.handleSubmit}
                  >
                    <CustomInputLabel data-test-id="email-address-label">Email address</CustomInputLabel>

                    <CustomInput
                      id="email"
                      name="email"
                      fullWidth
                      variant="outlined"
                      onChange={props.handleChange}
                      helperText={
                        <CustomErrorLabel
                          data-test-id="email-error-text"
                        >
                          {props.errors.email}
                        </CustomErrorLabel>
                      }
                      error={props.touched.email && !!props.errors.email}
                      placeholder="hello@example.com"
                      data-test-id="email-input"
                    />

                    <ResetPasswordButton data-test-id="reset-button" type="submit">Reset Password</ResetPasswordButton>
                  </Form>
                )
              }}
            </Formik>
          </Box>
          <CustomSnackbar 
            isOpen={this.state.isSnackbarOpen}
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            onClose={() => this.setState({ isSnackbarOpen: false })}
            message={this.state.snackbarMessage}
            data-test-id="snack-bar"
          />
        </>
      </LoginLayout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(ForgotPassword);
// Customizable Area End