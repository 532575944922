import React from "react";

// Customizable Area Start
import {
  Avatar,
  Box,
  Menu,
  MenuItem
} from "@material-ui/core";

import EmailAccountLogoutController, {
  Props,
} from "./EmailAccountLogoutController.web";
import { CustomSnackbar } from "../../../blocks/forgot-password/src/ForgotPassword.web";
// Customizable Area End

export class EmailAccountLogout extends EmailAccountLogoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start

    return (
      <Box>
        <Avatar
          onClick={this.handleClickAvatar}
          data-test-id="user-avatar"
        />

        <Menu
          data-test-id="user-menu"
          anchorEl={this.state.logoutAnchorEl}
          keepMounted
          open={Boolean(this.state.logoutAnchorEl)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "bottom", horizontal: "right" }}
          getContentAnchorEl={null}
          onClose={this.handleCloseMenu}
        >
          <MenuItem
            data-test-id="logout-option"
            onClick={this.handleClickLogout}
          >
            Logout
          </MenuItem>
        </Menu>

        <CustomSnackbar
          isOpen={this.state.isSnackbarOpen}
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={() =>
            this.setState({ isSnackbarOpen: false })
          }
          message={this.state.snackbarMessage}
          data-test-id="snack-bar"
          type={this.state.snackBarType}
        />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default EmailAccountLogout;
// Customizable Area End