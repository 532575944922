import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { FormikProps } from "formik";
import { WithStyles } from "@material-ui/core";
import { sessionProvider } from "./ForgotPasswordController.web";

export const requestApi = (data: object, header: object, endPoint: string) => {
  const httpBody = {
    data: data,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return requestMessage.messageId;
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
}
// Customizable Area End

interface S {
  // Customizable Area Startas
  showPassword: boolean;
  showConfirmPassword: boolean;
  token: string | null;
  loading: boolean;
  isOpenSnackbar: boolean;
  errorMessage: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordApiId: string = "";

  resetPasswordFormProps?: FormikProps<{
    password: string;
    confirmPassword: string;
  }> = undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationForgotPasswordMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      token: null,
      loading: false,
      isOpenSnackbar: false,
      errorMessage: ""
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.redirection();
    // Customizable Area End
  }

  // Customizable Area Start
  redirection = () => {
    const token = sessionProvider("get", "token");
    const tokenType = sessionProvider("get", "token_type");

    if (!token || (token && tokenType != "otp_reset_token")) {
      setTimeout(() => {
        this.props.navigation.navigate("EmailAccountLoginBlock");
      }, 10);
    }
  }

  updateResetPasswordFormProps = (
    props: FormikProps<{
      password: string;
      confirmPassword: string;
    }>
  ) => {
    this.resetPasswordFormProps = props;
  };

  resetPassword = (values: { password: string, confirmPassword: string }) => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      "token": sessionProvider("get", "token")
    };

    const data = {
      attributes: {
        new_password: values.password,
        password_confirmation: values.confirmPassword
      }
    };

    this.resetPasswordApiId = requestApi(data, header, "bx_block_forgot_password/passwords/reset_password");
  }

  handleApiResponse = async (responseJson: any) => {
    this.setState({ loading: false });

    if (
      responseJson.error &&
      this.resetPasswordFormProps
    ) {
      this.resetPasswordFormProps.setErrors({
        password: responseJson.error,
      });
      return;
    }

    sessionProvider("set", "token_type", "reseted_password");
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  handleShowPassword = (type: number) => {
    if (type === 1) {
      this.setState({
        showPassword: !this.state.showPassword
      });
    } else {
      this.setState({
        showConfirmPassword: !this.state.showConfirmPassword
      });
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestErrorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestErrorMessage !== undefined) {
      this.setState({
        isOpenSnackbar: true,
        errorMessage: apiRequestErrorMessage,
        loading: false
      })
      return;
    }

    if (apiRequestCallId === this.resetPasswordApiId) {
      this.handleApiResponse(responseJson);
    }
    // Customizable Area End
  }
}