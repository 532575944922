import React, { FunctionComponent, ReactNode } from "react";
// Customizable Area Start
import {
    Box,
    Grid
} from "@mui/material";


type FlexDirection = "column" | "inherit" | "-moz-initial" | "initial" | "revert" | "unset" | "column-reverse" | "row" | "row-reverse" | undefined;

const styles = {
    leftView: {
        backgroundColor: '#0E0F2B',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logoImg: {
        width: '389px',
        height: '389px',
        margin: 0,
        justifyContent: 'center'
    },
    rightView: {
        backgroundColor: '#F1F5F9',
        height: '100vh',
        padding: '10%',
        margin: 0,
        display: 'flex',
        flexDirection: 'column' as FlexDirection,
        gap: 12
    }
};

interface Props {
    children: ReactNode;
}

const logo = require("./main_logo.png")

const LoginLayout: FunctionComponent<Props> = (props) => {
    
    return (
        <Grid container>
            <Grid item md={6} sm={12} xs={12} lg={6}>
                <Box style={styles.leftView}>
                    <img src={logo?.default || logo || ""} style={styles.logoImg} />
                </Box>
            </Grid>
            <Grid item md={6} sm={12} xs={12} lg={6}>
                <Box style={styles.rightView}>
                    {props.children}
                </Box>
            </Grid>
        </Grid>
    );
};

export default LoginLayout;
// Customizable Area End