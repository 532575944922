import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { FormikProps } from "formik";
import { requestApi } from "../../../blocks/tasks/src/ClaimsDashboardController.web";

export type FormikFormProps = {
  pay_amount: string;
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props {
  token: string;
  claimId: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
  handleShowSnackBar: (open: boolean, type: string, message: string) => void;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  payAmount: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SubmitPayAmountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  formProps!: FormikProps<FormikFormProps>;

  submitPayAmountApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      payAmount: "",
      loading: false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  updateFormProps = (props: FormikProps<FormikFormProps>) => {
    this.formProps = props;
  };

  handleSubmit = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      "token": this.props.token
    };

    const data = {
      claim_id: this.props.claimId,
      claim: {
        claim_amount: this.formProps.values.pay_amount
      }
    };

    this.submitPayAmountApiId = requestApi(
      header,
      "PUT",
      "bx_block_custom_forms/claims/update_claim_amount",
      data
    );
  }

  handleChangePayAmount = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, "");

    const maxAmountRegex = /^(999999999999|\d{0,12})$/;

    if (!maxAmountRegex.test(numericValue)) {
      return;
    }

    this.formProps?.setFieldValue("pay_amount", numericValue);

    this.setState({ payAmount: this.formatCurrency(numericValue) });
  }

  formatCurrency = (value: string) => {
    const numericValue = parseInt(value, 10);

    if (isNaN(numericValue)) {
      return "";
    }

    const parts = numericValue.toString().split(".");
    const integerPart = parts[0];
    const formattedIntegerPart = integerPart.split('').reverse().join('')
      .replace(/(\d{3}(?=\d))/g, '$&,')
      .split('').reverse().join('');

    return `$${formattedIntegerPart}${parts[1] ? '.' + parts[1] : ''}`;
  };

  handleClose = () => {
    this.setState({ payAmount: "" });
    this.props.handleCloseModal();
  }

  handleApiResponse = async (responseJson: any) => {
    this.setState({ loading: false });

    if (responseJson.error) {
      this.props.handleShowSnackBar(
        true,
        "error",
        responseJson.error
      );

      return;
    }

    if (responseJson.errors && responseJson.errors[0]) {
      this.props.handleShowSnackBar(
        true,
        "error",
        responseJson.errors[0]
      );

      return;
    }

    this.props.handleShowSnackBar(
      true,
      "success",
      responseJson.message
    );

    this.handleClose();
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received: ", message);

    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const apiErrorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiErrorMessage !== undefined) {
      this.setState({
        loading: false
      });

      this.props.handleShowSnackBar(true, "error", apiErrorMessage);
      
      return;
    }

    if (apiId === this.submitPayAmountApiId) {
      this.handleApiResponse(response);
    }
    // Customizable Area End
  }
}