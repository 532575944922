import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Button,
} from "@mui/material";

import ForgotPasswordOTPController, {
  Props,
} from "./ForgotPasswordOTPController.web";
import { styled } from "@mui/material/styles";
import LoginLayout from "../../../components/src/LoginLayout.web";
import { createStyles, withStyles } from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { CustomSnackbar } from "./ForgotPassword.web";

const styles = createStyles({
  root: {
    flexGrow: 1,
    padding: "10% 0"
  },
  sendOtpForm: {
    maxWidth: '100%',
    textAlign: "center",
    justifyContent: "center"
  },
  otpInput: {
    width: "50px",
    height: "50px",
    border: "1px solid #0E0F2B",
    textAlign: 'center' as const,
    fontSize: 30,
    lineHeight: 1
  },
  otpGroup: {
    display: 'flex',
    gap: 15,
    justifyContent: "center"
  }
});

const ResendOTPButton = styled(Button)(() => ({
  minWidth: '230px',
  height: '40px',
  padding: '10px 18px',
  color: '#0E0F2B',
  fontWeight: 400,
  fontSize: '20px',
  top: '70px',
  textTransform: 'none',
  border: '1px solid #0E0F2B',
  borderRadius: '0'
}));

const CustomOTPVerificationLabel = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 80,
  lineHeight: '80px'
}));

const CustomAdditionalInfoLabel = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 20,
  marginBottom: '20px'
}));

const CustomErrorLabel = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 400,
  color: "#EC441E",
  textAlign: 'center',
  marginTop: 20
}));
// Customizable Area End

export class ForgotPasswordOTP extends ForgotPasswordOTPController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props

    return (
      <LoginLayout>
        <Box className={classes.root}>
          <Loader loading={this.state.loading} />
          <CustomOTPVerificationLabel data-test-id="otp-verification-label">OTP Verification</CustomOTPVerificationLabel>
          <CustomAdditionalInfoLabel data-test-id="additional-info-label">Enter the verification code we just sent to your email</CustomAdditionalInfoLabel>

          <Box className={classes.sendOtpForm}>
            <div className={classes.otpGroup} data-test-id="otp-group">
              {this.state.otpItems.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  inputMode="numeric"
                  autoComplete="one-time-code"
                  pattern="\d{1}"
                  className={classes.otpInput}
                  maxLength={4}
                  value={digit}
                  onChange={(e) => this.handleOnchange(e, index)}
                  onKeyDown={this.handleKeyDown}
                  onFocus={this.handleOnFocus}
                  data-test-id={"otp-digit-" + index}
                />
              ))}
            </div>

            <CustomErrorLabel>{this.state.errorMessage}</CustomErrorLabel>
            <Typography>OTP code expiry time {this.state.timer}s</Typography>

            <ResendOTPButton
              variant="outlined"
              data-test-id="resend-otp-button"
              onClick={this.handleClickResendOtp}
            >
              Re-send OTP Code
            </ResendOTPButton>
          </Box>
        </Box>
        <CustomSnackbar
          isOpen={this.state.isSnackbarShow}
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={() =>
            this.setState({ isSnackbarShow: false })
          }
          message={this.state.snackbarMessages}
          data-test-id="snack-bar"
        />
      </LoginLayout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(ForgotPasswordOTP);
// Customizable Area End