import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";

import NewPasswordController, {
  Props,
} from "./NewPasswordController.web";

import { styled } from "@mui/material/styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoginLayout from "../../../components/src/LoginLayout.web";
import { createStyles, withStyles } from "@material-ui/core";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import Loader from "../../../components/src/Loader.web";
import { CustomSnackbar } from "./ForgotPassword.web";

const styles = createStyles({
  newPasswordForm: {
    width: '100%'
  }
});

const ForgotPasswordLabel = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 48,
  lineHeight: '40px'
}));

const PasswordLabel = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  marginTop: 20
}));

const AdditionalInfoLabel = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 20
}));

const PasswordInput = styled(TextField)(() => ({
  backgroundColor: "transparent",
  width: '100%',
  height: '44px',
  marginBottom: '12px',
  '& .MuiOutlinedInput-input': {
    padding: '17px 8px',
    fontSize: '18px',
  },
  '& input::-ms-reveal, input::-ms-clear': {
    display: 'none'
  }
}));

const ConfirmPasswordLabel = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  marginTop: 40
}));

const ErrorLabel = styled(Typography)(() => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: "red"
}));

const ResetButton = styled(Button)(() => ({
  width: '100%',
  height: '78px',
  padding: '10px 18px',
  borderRadius: '8px',
  background: 'linear-gradient(90deg, #F0644B 0%, #FAA52E 100%)',
  color: '#FFFFFF',
  fontWeight: 700,
  fontSize: '24px',
  top: '70px',
  textTransform: 'none',
}));

const PasswordOption = styled(Typography)(() => ({
  color: '#EC441E',
  borderBottom: '2px solid #EC441E',
  width: 'fit-content',
  padding: 2,
  margin: '32px 0px',
  fontWeight: 700,
  fontSize: 20
}));

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Please enter new password")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character")
    .matches(/[a-z]/, "Password must contain at least one lowercase character")
    .matches(/\d/, "Password must contain at least one numeric character"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Confirm password does not match')
    .required("Please enter confirm password")
});
// Customizable Area End

export class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;

    return (
      <LoginLayout>
        <>
          <Loader loading={this.state.loading} />
          <ForgotPasswordLabel data-test-id="forgot-password-label">Forgot Password?</ForgotPasswordLabel>
          <AdditionalInfoLabel data-test-id="additional-info-label">Enter your email address to get password reset link</AdditionalInfoLabel>
          <PasswordOption data-test-id="password-option-label">Password</PasswordOption>

          <Box className={classes.newPasswordForm}>
            <Formik
              initialValues={{
                password: "",
                confirmPassword: ""
              }}
              validationSchema={validationSchema}
              onSubmit={this.resetPassword}
              data-test-id="reset-formik"
            >
              {(props) => {
                this.updateResetPasswordFormProps(props);

                return (
                  <Form
                    data-test-id="reset-password-form"
                    onSubmit={props.handleSubmit}
                  >
                    <PasswordLabel data-test-id="password-label">Password</PasswordLabel>

                    <PasswordInput
                      name="password"
                      fullWidth
                      variant="outlined"
                      onChange={props.handleChange}
                      helperText={
                        <ErrorLabel
                          data-test-id="password-error-text"
                        >
                          {props.errors.password}
                        </ErrorLabel>
                      }
                      error={props.touched.password && !!props.errors.password}
                      placeholder=".............."
                      data-test-id="password-input"
                      type={this.state.showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            onClick={() => this.handleShowPassword(1)}
                            position="end"
                            style={{ cursor: "pointer" }}
                            data-test-id="password-input-adornment"
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}

                          </InputAdornment>
                        ),
                      }}
                    />

                    <ConfirmPasswordLabel data-test-id="confirm-password-label">Re-Enter Password</ConfirmPasswordLabel>

                    <PasswordInput
                      name="confirmPassword"
                      fullWidth
                      variant="outlined"
                      onChange={props.handleChange}
                      helperText={
                        <ErrorLabel
                          data-test-id="confirm-password-error-text"
                        >
                          {props.errors.confirmPassword}
                        </ErrorLabel>
                      }
                      error={props.touched.confirmPassword && !!props.errors.confirmPassword}
                      placeholder=".............."
                      data-test-id="confirm-password-input"
                      type={this.state.showConfirmPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            onClick={() => this.handleShowPassword(2)}
                            position="end"
                            style={{ cursor: "pointer" }}
                            data-test-id="confirm-password-input-adornment"
                          >
                            {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}

                          </InputAdornment>
                        ),
                      }}
                    />

                    <ResetButton data-test-id="reset-button" type="submit">Reset Password</ResetButton>
                  </Form>
                )
              }}
            </Formik>
          </Box>

          <CustomSnackbar
            isOpen={this.state.isOpenSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            onClose={() =>
              this.setState({ isOpenSnackbar: false })
            }
            message={this.state.errorMessage}
            data-test-id="snack-bar"
          />
        </>
      </LoginLayout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(NewPassword);
// Customizable Area End