import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { requestApi } from "../../../blocks/tasks/src/ClaimsDashboardController.web";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props {
  token: string
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean,
  logoutAnchorEl: HTMLElement | null,
  isSnackbarOpen: boolean,
  snackbarMessage: string,
  snackBarType: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountLogoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  logoutApiId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.LoginSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      logoutAnchorEl: null,
      isSnackbarOpen: false,
      snackbarMessage: "",
      snackBarType: "error"
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleClickAvatar = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ logoutAnchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ logoutAnchorEl: null });
  }

  handleClickLogout = () => {
    this.setState({
      loading: true,
      logoutAnchorEl: null
    });

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.props.token
    };

    this.logoutApiId = requestApi(header, configJSON.loginAPiMethod, "bx_block_login/logins/logout");
  }

  handleApiResponse = async (responseJson: any) => {
    this.setState({ loading: false });

    if (responseJson.error) {
      this.setState({
        isSnackbarOpen: true,
        snackbarMessage: responseJson.error,
        snackBarType: "error"
      });

      return;
    }

    this.setState({
      isSnackbarOpen: true,
      snackBarType: "success",
      snackbarMessage: responseJson.message,
    });

    this.deleteLoginInfor();

    window.location.reload();
  }

  deleteLoginInfor = () => {
    StorageProvider.clearStorage();
    sessionStorage.clear();
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiErrorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiErrorMessage !== undefined) {
      this.setState({
        loading: false,
        snackBarType: "error",
        isSnackbarOpen: true,
        snackbarMessage: apiErrorMessage,
      })
      return;
    }

    if (apiId === this.logoutApiId) {
      this.handleApiResponse(response);
    }
    // Customizable Area End
  }
}