import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { sessionProvider } from "./ForgotPasswordController.web";
import { requestApi } from "./NewPasswordController.web";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
}

const regex = new RegExp(/^\d+$/);
// Customizable Area End

interface S {
  // Customizable Area Start
  otpCode: string;
  tokenType: string | null;
  token: string | null;
  resetEmail: string | null;
  errorMessage: string;
  otpItems: Array<string>;
  loading: boolean;
  timer: number;
  isSnackbarShow: boolean;
  snackbarMessages: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPasswordOTPController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgotPasswordOTPApiId: string = "";
  resendOTPApiId: string = "";
  timerInterval: ReturnType<typeof setInterval> | null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationForgotPasswordOTPMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    const savedTimer = sessionStorage.getItem("timer");

    this.state = {
      otpCode: '',
      tokenType: null,
      token: null,
      resetEmail: null,
      errorMessage: "",
      otpItems: this.getOtpItems('', 4),
      loading: false,
      timer: savedTimer ? Math.max(0, parseInt(savedTimer, 10)) : 60,
      isSnackbarShow: false,
      snackbarMessages: ""
    };

    this.timerInterval = null;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.redirection();
    // Customizable Area End
  }

  // Customizable Area Start
  redirection = () => {
    const tokenType = sessionProvider("get", "token_type");
    const token = sessionProvider("get", "token");
    const email = sessionProvider("get", "email");

    if (token && tokenType && tokenType == 'forgot_password') {
      this.setState({ resetEmail: email ? email : '' });
      this.setState({ token: token });
      this.setState({ tokenType: tokenType });

      if (this.state.timer > 0) {
        this.startTimer();
      }

      return;
    }

    setTimeout(() => {
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }, 10);
  }

  async componentWillUnmount() {
    this.clearTimer();
  }

  startTimer = () => {
    this.timerInterval = setInterval(() => {
      this.setState(
        (prevState) => ({
          timer: prevState.timer - 1
        }),
        () => {
          sessionStorage.setItem("timer", this.state.timer.toString());
        }
      );

      if (this.state.timer === 0) {
        this.clearTimer();
      }
    }, 1000);
  }

  clearTimer() {
    clearInterval(this.timerInterval as ReturnType<typeof setInterval>);
    this.timerInterval = null;
  }

  getOtpItems(value: string, valueLength: number): Array<string> {
    const valueArray = value.split('');
    const items: Array<string> = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (regex.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }

  handleClickResendOtp = () => {
    this.setState({
      errorMessage: '',
      loading: true
    });

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const data = {
      attributes: {
        email: this.state.resetEmail
      }
    };

    this.resendOTPApiId = requestApi(data, header, "bx_block_forgot_password/passwords/forgot_password");
  }

  handleResendOtpApiResponse = async (responseJson: any) => {
    this.setState({ loading: false });

    if (
      responseJson.error &&
      responseJson.error[0]
    ) {
      this.setState({ errorMessage: responseJson.error[0] });
      return;
    }

    this.clearTimer();

    this.setState({ timer: 60 }, () => {
      sessionStorage.setItem("timer", this.state.timer.toString());
      this.startTimer();
    }
    );

    sessionProvider("set", "token", responseJson.token);
    sessionProvider("set", "token_type", "forgot_password");
  }

  verifyOtpCode = (otp: string) => {
    this.setState({ loading: true });

    const token = window.sessionStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      "token": token
    };

    const data = {
      attributes: {
        token: token,
        email_otp: otp
      }
    };

    this.forgotPasswordOTPApiId = requestApi(data, header, "bx_block_forgot_password/passwords/verify_otp");
  }

  handleVerifyOtpApiResponse = async (responseJson: any) => {
    this.setState({ loading: false });

    if (responseJson.error) {
      this.setState({ errorMessage: responseJson.error });
      return;
    }

    sessionProvider('set', "token", responseJson.token);
    sessionProvider('set', "token_type", "otp_reset_token");

    this.props.navigation.navigate("NewPassword");
  }

  handleOnchange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const target = e.target;
    let targetValue = target.value;
    const isTargetValueDigit = regex.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    let newValue = this.state.otpCode.substring(0, index) + targetValue + this.state.otpCode.substring(index + 1);
    this.setState({
      otpCode: newValue,
      otpItems: this.getOtpItems(newValue, 4)
    });

    if (!isTargetValueDigit) {
      return;
    }

    const nextElementSibling = target.nextElementSibling as HTMLInputElement || null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }

    newValue = newValue.trim();

    if (newValue.length === 4) {
      console.log(newValue)
      this.verifyOtpCode(newValue);
    }
  }

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    target.setSelectionRange(0, target.value.length);

    if (e.key !== 'Backspace' || target.value !== '') return;

    const previousElementSibling = target.previousElementSibling as HTMLInputElement || null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  }

  handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;
    target.setSelectionRange(0, target.value.length);
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestErrorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestErrorMessage !== undefined) {
      this.setState({
        isSnackbarShow: true,
        snackbarMessages: apiRequestErrorMessage,
        loading: false
      })
      return;
    }

    if (apiRequestCallId === this.forgotPasswordOTPApiId) {
      this.handleVerifyOtpApiResponse(responseJson);
    }

    if (apiRequestCallId === this.resendOTPApiId) {
      this.handleResendOtpApiResponse(responseJson);
    }
    // Customizable Area End
  }
}