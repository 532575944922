import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { FormikProps } from "formik";
import { requestApi } from "./NewPasswordController.web";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
}

export const sessionProvider = (type: string, key: string, value: string = "") => {
  if (type === "get") {
    return window.sessionStorage.getItem(key);
  }

  return window.sessionStorage.setItem(key, value);
}

export interface SnackbarProps {
  isOpen: boolean;
  autoHideDuration: number;
  anchorOrigin: {
    vertical: 'top' | 'bottom',
    horizontal: 'left' | 'center' | 'right'
  },
  onClose: () => void,
  message: string,
  type?: string
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  otpCode: string;
  isSnackbarOpen: boolean;
  snackbarMessage: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgotPasswordApiId: string = "";

  forgotPasswordFormProps?: FormikProps<{
    email: string;
  }> = undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationForgotPasswordMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      otpCode: "",
      loading: false,
      isSnackbarOpen: false,
      snackbarMessage: ""
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  resetPassword = (values: { email: string }) => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const data = {
      attributes: values
    };

    this.forgotPasswordApiId = requestApi(data, header, "bx_block_forgot_password/passwords/forgot_password");
  }

  handleApiResponse = async (responseJson: any) => {
    this.setState({ loading: false });

    if (
      responseJson.error &&
      responseJson.error[0] &&
      this.forgotPasswordFormProps
    ) {
      this.forgotPasswordFormProps.setErrors({
        email: responseJson.error[0],
      });
      return;
    }

    this.setState({ otpCode: responseJson.otp });
    sessionProvider("set", "timer", "60");
    sessionProvider("set", "token", responseJson.token);
    sessionProvider("set", "token_type", "forgot_password");
    sessionProvider("set", "email", this.forgotPasswordFormProps ? this.forgotPasswordFormProps.values.email : "");

    this.props.navigation.navigate("ForgotPasswordOTP");
  }

  updateResetPasswordFormProps = (
    props: FormikProps<{
      email: string;
    }>
  ) => {
    this.forgotPasswordFormProps = props;
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestErrorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestErrorMessage !== undefined) {
      this.setState({
        isSnackbarOpen: true,
        snackbarMessage: apiRequestErrorMessage,
        loading: false
      })
      return;
    }

    if (apiRequestCallId === this.forgotPasswordApiId) {
      this.handleApiResponse(responseJson);
    }
    // Customizable Area End
  }
}