import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  TextField,
  FormControlLabel,
  InputAdornment,
  Checkbox,
  Button,
} from "@mui/material";

import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

import EmailAccountLoginController, {
  LoginEnum,
  Props,
} from "./EmailAccountLoginController.web";
import { styled } from "@mui/material/styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoginLayout from "../../../components/src/LoginLayout.web";
import { withStyles } from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { CustomSnackbar } from "../../../blocks/forgot-password/src/ForgotPassword.web";

const styles = {
  loginForm: {
    width: '100%'
  },
  passwordLabelArea: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: '20px',
  },
  rememberArea: {
    marginTop: '28px'
  },
  loginLabel: {
    fontWeight: 700,
    fontSize: 48,
    lineHeight: '40px'
  },
  successResetLable: {
    fontWeight: 400,
    fontSize: 40,
    lineHeight: '40px',
    marginBottom: 40
  },
  root: {
    position: "absolute" as const,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute" as const,
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
};

const CustomLoginButton = styled(Button)(() => ({
  width: '100%',
  height: '78px',
  padding: '10px 18px',
  borderRadius: '8px',
  background: 'linear-gradient(90deg, #F0644B 0%, #FAA52E 100%)',
  color: '#FFFFFF',
  fontWeight: 700,
  fontSize: '24px',
  top: '15px',
  textTransform: 'none',
}));

const CustomWelcomeLabel = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 20
}));

const CustomEmailOption = styled(Typography)(() => ({
  color: '#EC441E',
  borderBottom: '2px solid #EC441E',
  width: 52,
  padding: 2,
  margin: '32px 0px',
  fontWeight: 700,
  fontSize: 20
}));

const CustomInput = styled(TextField)(() => ({
  backgroundColor: "transparent",
  width: '100%',
  height: '44px',
  marginBottom: '12px',
  '& .MuiOutlinedInput-input': {
    padding: '17px 8px',
    fontSize: '18px',
  },
  '& input::-ms-reveal, input::-ms-clear': {
    display: 'none'
  }
}));

const CustomInputLabel = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  marginTop: '20px'
}));

const CustomForgotLabel = styled(Typography)(() => ({
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 700,
  color: "#EC441E",
  marginTop: '20px'
}));

const CustomRememberLabel = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 400,
}));

const CustomErrorLabel = styled(Typography)(() => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: "red"
}));

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your email"),

  password: Yup.string()
    .required("Please enter your password"),
});
// Customizable Area End

export class EmailAccountLogin extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start

    return (
      <LoginLayout>
        <>
          <Loader loading={this.state.loading} />
          {this.state.screenLabel == LoginEnum.LoginScreen ?
            <>
              <Typography
                data-test-id="login-label"
                style={styles.loginLabel}
              >
                {this.state.screenLabel}
              </Typography>
              <CustomWelcomeLabel data-test-id="welcome-label">Welcome back to Flighthound</CustomWelcomeLabel>
              <CustomEmailOption data-test-id="email-option-label">Email</CustomEmailOption>
            </> :
            <Typography
              data-test-id="login-label"
              style={styles.successResetLable}
            >
              {this.state.screenLabel}
            </Typography>
          }

          <Box style={styles.loginForm}>

            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={this.login}
              data-test-id="login-formik"
            >
              {(props) => {
                this.updateLoginFormProps(props);

                return (
                  <Form
                    data-test-id="login-form"
                    onSubmit={props.handleSubmit}
                  >
                    <CustomInputLabel data-test-id="email-address-label">Email address</CustomInputLabel>

                    <CustomInput
                      id="email"
                      name="email"
                      fullWidth
                      variant="outlined"
                      onChange={props.handleChange}
                      helperText={
                        <CustomErrorLabel
                          data-test-id="email-error-text"
                        >
                          {props.errors.email}
                        </CustomErrorLabel>
                      }
                      error={props.touched.email && !!props.errors.email}
                      placeholder="hello@example.com"
                      data-test-id="email-address-input"
                    />

                    <Box style={styles.passwordLabelArea}>
                      <CustomInputLabel data-test-id="password-label">Password</CustomInputLabel>

                      <CustomForgotLabel
                        onClick={this.forgotPassword}
                        data-test-id="forgot-password-label"
                      >
                        Forgot Password?
                      </CustomForgotLabel>
                    </Box>

                    <CustomInput
                      id="password"
                      name="password"
                      fullWidth
                      variant="outlined"
                      onChange={props.handleChange}
                      error={props.touched.password && !!props.errors.password}
                      helperText={
                        <CustomErrorLabel
                          data-test-id="email-error-text"
                        >
                          {props.errors.password}
                        </CustomErrorLabel>
                      }
                      placeholder="......................"
                      type={this.state.showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            onClick={this.handleShowPassword}
                            position="end"
                            style={{ cursor: "pointer" }}
                            data-test-id="input-adornment"
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}

                          </InputAdornment>
                        ),
                      }}
                      data-test-id="password-input"
                    />

                    <FormControlLabel
                      data-test-id="remember-me-form-control"
                      control={
                        <Checkbox
                          checked={this.state.rememberMe}
                          onChange={this.handleClickRememberMe}
                          name="rememberMe"
                          style={{ color: "#EC441E" }}
                          data-test-id="remember-me-checkbox"
                        />}
                      label={<CustomRememberLabel data-test-id="remember-me-label" >Keep me signed in</CustomRememberLabel>}
                      style={styles.rememberArea}
                    />

                    <CustomLoginButton data-test-id="login-button" type="submit">Login</CustomLoginButton>
                  </Form>
                )
              }}
            </Formik>
          </Box>

          <CustomSnackbar
            isOpen={this.state.isSnackbarOpen}
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            onClose={() =>
              this.setState({ isSnackbarOpen: false })
            }
            message={this.state.snackbarMessage}
            data-test-id="snack-bar"
          />
        </>
      </LoginLayout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(EmailAccountLogin);
// Customizable Area End
