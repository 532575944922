import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography
} from "@material-ui/core";

import { styled } from "@mui/material/styles";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import SubmitPayAmountController, {
  Props,
} from "./SubmitPayAmountController.web";

import { Formik, Form } from "formik";
import * as Yup from "yup";

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 500
  },
  paper: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    width: '40%',
    minWidth: 300,
    minHeight: 300,
    outline: 'none',
    fontSize: 14,
    alignContent: 'center',
  },
  mainForm: {
    padding: '50px 80px',
  },
  errorLabel: {
    fontSize: '0.75rem',
    fontWeight: 400,
    color: "red"
  },
  closeIcon: {
    textAlign: 'right'
  }
};

const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#000000',
    },
    '& fieldset': {
      borderRadius: 8,
      height: '44px',
      borderColor: '#000000',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#000000',
    },
  },
  backgroundColor: "transparent",
  width: '70%',
  margin: 20,
  '& .MuiOutlinedInput-input': {
    padding: '10px 14px',
    fontSize: '14px'
  },
}));

const CustomButton = styled(Button)(() => ({
  fontSize: 14,
  fontWeight: 700,
  color: '#0E0F2B',
  background: '#FFFFFF',
  marginTop: 10,
  border: '1px solid #000000',
  width: 100,
  textTransform: 'none',
}));

const validationSchema = Yup.object({
  pay_amount: Yup.number()
    .typeError("Please enter a valid amount")
    .required("Pay amount is required")
});
// Customizable Area End

export class SubmitPayAmount extends SubmitPayAmountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start

    return (
      <Modal
        open={this.props.modalOpen}
        style={styles.modal}
      >
        <Box style={styles.paper}>
          <Formik
            initialValues={{
              pay_amount: ""
            }}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            data-test-id="formik-form"
          >
            {(props) => {
              this.updateFormProps(props);

              return (
                <>
                  <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <HighlightOffIcon
                      onClick={this.handleClose}
                      data-test-id="close-icon"
                    />
                  </Box>

                  <Box style={styles.mainForm}>
                    <Typography gutterBottom>
                      Please specify the amount that the user will be paid out based on this claim. This will appear in their claim history
                    </Typography>

                    <Form
                      data-test-id="reset-password-form"
                      onSubmit={props.handleSubmit}
                    >
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <CustomTextField
                          data-test-id="pay-amount"
                          variant="outlined"
                          name="pay_amount"
                          onChange={this.handleChangePayAmount}
                          value={this.state.payAmount}
                          helperText={
                            <Typography
                              style={styles.errorLabel}
                              data-test-id="error-text"
                            >
                              {props.errors.pay_amount}
                            </Typography>
                          }
                          error={props.touched.pay_amount && !!props.errors.pay_amount}
                        />
                      </Box>

                      <Box display="flex" justifyContent="center" alignItems="center">
                        <CustomButton
                          type="submit"
                        >
                          Submit
                        </CustomButton>
                      </Box>
                    </Form>
                  </Box>
                </>
              )
            }}
          </Formik>
        </Box>
      </Modal>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default SubmitPayAmount;
// Customizable Area End